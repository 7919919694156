import React, { useState, useContext, useEffect } from 'react';
import '../components/MakeLogo.css';
import CustomCardCarousel from '../components/Card';
import Step2 from '../components/Step2';
import Step3 from '../components/Step3';
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import styles from "./Step1.module.css";
import initials from "../assets//Group 1000002360.png";
import ibm from "../assets/image 43.png";
import cnn from "../assets/image 44.png";
import hbo from "../assets/image 45.png";
import icon from "../assets/Vector.png";
import adidas from "../assets/adidas 1.png";
import king from "../assets/image 48.png";
import lacoste from "../assets/image 47.png";
import motor from "../assets/image 49.png";
import nfl from "../assets/image 50.png";
import starbucks from "../assets/image 51.png";
import google from "../assets/image 52.png";
import coce from "../assets/image 53.png";
import visa from "../assets/image 54.png";
import wreath from "../assets/wreath 1.png";
import Geometry1 from "../assets/image 65.png";
import Geometry2 from "../assets/image 66.png";
import Geometry3 from "../assets/image 67.png";
import Geometry from "../assets/Geometry.png";
import text from "../assets/text-formatting.png";
import { useNavigate } from 'react-router-dom';
import styles2 from "../components/card.module.css";
import { LogoContext } from '../contexts/LogoGeneratorContext';
import { useParams } from "react-router-dom";

const cardData = [
  {
    image: initials,
    caption: "Initials",
    description: "Used to represent brands by initials. Ideal for long names.",
    icons: [ibm, cnn, hbo],

  },
  {
    image: icon,
    caption: "Icon",
    description: "Combines text and symbol. Most commonly use logo type",
    icons: [adidas, lacoste, king],
  },
  {
    image: wreath,
    caption: "Badge",
    description: "Text within symbol, Used for a classic retro look.",
    icons: [motor, starbucks, nfl],

  },
  {
    image: text,
    caption: "Text",
    description: "Used to represent brands by initials. Ideal for long names.",
    icons: [google, coce, visa],
  },
  {
    image: Geometry,
    caption: "Geometry",
    description: "Used to represent brands by initials. Ideal for long names.",
    icons: [Geometry1, Geometry2, Geometry3],
  },

];



const BodySection = () => {
  const { logoText, logoIndustry } = useParams();
  const [currentStep, setCurrentStep] = useState(1);
  const navigate = useNavigate();
  const { logoStyles, selectedLayouts, selectedFonts, setLogoText, setLogoIndustry } = useContext(LogoContext);

  useEffect(() => {
    if (logoText && logoIndustry) {
      setLogoText(logoText);
      setLogoIndustry(logoIndustry);
      console.log("Updated industry n text")
    } 
  }, [setLogoText, setLogoIndustry, logoText, logoIndustry]);

  const isNextButtonDisabled = () => {
    if (currentStep === 1) {
      // Step 1 requires at least one logo style to be selected
      return logoStyles.length === 0;
    }

    if (currentStep === 3) {
      // Step 2 requires at least one valid logo style
      return selectedLayouts.length === 0;
    }

    if (currentStep === 2) {
      // Step 2 requires at least one valid logo style
      return selectedFonts.length === 0;
    }

    // No restrictions for Step 3
    return false;
  };

  const handleNext = () => {
    setCurrentStep((prevStep) => {

      if (prevStep === 3) {
        // If on Step 3, navigate to '/sample-logos'
        navigate('/sample-logos');
        return prevStep;
      }

      // Determine the logo styles
      const hasIconOrGeometry = logoStyles.includes('Icon') || logoStyles.includes('Geometry');
      const hasTextOrInitials = logoStyles.includes('Text') || logoStyles.includes('Initials');
      const hasBadge = logoStyles.includes('Badge');

      // Navigate based on logo styles
      if (hasTextOrInitials && !hasIconOrGeometry && !hasBadge) {
        navigate('/sample-logos');
        return prevStep;
      }

      if (prevStep === 2 && hasBadge && !hasIconOrGeometry) {
        navigate('/sample-logos');
        return prevStep;
      }

      if (prevStep === 2 && hasBadge && hasIconOrGeometry) {
        return 3;
      }

      // Step navigation
      if (prevStep === 1 && (hasIconOrGeometry || hasBadge)) {
        return 2; // Move to Step 2 if it’s not already there
      }

      if (prevStep === 2 && (hasIconOrGeometry || hasBadge)) {
        return Math.min(prevStep + 1, 3); // Move to Step 3 if needed
      }

      return Math.min(prevStep + 1, 3); // Move to the next step if possible
    });
  };

  const handleBack = () => {
    setCurrentStep((prevStep) => Math.max(prevStep - 1, 1));
  };

  return (
    <>
      <div className='navbarOuterContainer'>
        <Navbar />
      </div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>

        <div className={styles.bodySection} style={{ paddingTop: '4.52rem' }}>

          {currentStep === 1 && <>

            <div className={`col-12 col-md-12 col-sm-12 col-xs-10 removePadding ${styles.headingContainer}`} style={{ padding: 0 }}>
              <h1 className={`${styles.stepheading} text-md-left`}
              // style={{fontFamily:'Roboto', fontWeight:'400',fontSize: '1.0625rem'}}
              >
                Step 1/3
              </h1>

              <h2 className={`${styles.logoSelectionHeading} text-md-left`}>
                Select all of the logo styles you like
              </h2>
              <p className={`${styles.preferencestext} text-md-left`}>
                Your selections will help us understand your preferences.
              </p>
            </div>

            <CustomCardCarousel cardData={cardData} dataType='style' />
          </>}
          {currentStep === 2 && (logoStyles.includes('Icon') || logoStyles.includes('Geometry') || logoStyles.includes('Badge')) && <Step2 />}

          {currentStep === 3 && (logoStyles.includes('Icon') || logoStyles.includes('Geometry')) && <Step3 />}

          {/* <Step2/>   
       <Step3/> */}
          <div className={styles.buttonContainer}>
            <button className={styles2.backButton} onClick={handleBack} disabled={currentStep === 1}>
              Back
            </button>
            <button
              className={styles2.sliderButton}
              onClick={handleNext}
              disabled={isNextButtonDisabled()}
            >
              <span className={styles2.buttonText}>Next</span>
              <span className={styles2.arrowRight}>
                <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
              </span>
            </button>
          </div>
        </div>


        <Footer />
      </div>
    </>
  );
};

export default BodySection;
