import { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import Homepage from "./pages/Homepage";
import Graphics from "./pages/GraphicDesignService";
import Frame15 from "./pages/SampleLogos";
import Home from "./pages/Step1";
import Animation from "./pages/Animation";
import Homepage1 from "./pages/LogoMakerpage";
import CustomDesign from "./pages/CustomDesignServices";
import TextLogoGenerator, { RandomStyleInitialLogos } from "./components/TextLogoGenerator";
import IconLogoGenerator from "./components/IconLogoGenerator";
import BadgeStyleLogos from "./components/BadgeStyleLogos";
import 'bootstrap/dist/css/bootstrap.min.css';
import { LogoProvider } from './contexts/LogoGeneratorContext';


function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);




  return (
    <LogoProvider>
    <Routes>

      {/* <Route path="/" element={<Homepage1 />} /> */}
      <Route path="/:logoText/:logoIndustry" element={<Home />} />
      <Route path="/home-page" element={<Homepage />} />
      <Route path="/text-logo-generator" element={<TextLogoGenerator />} />
      <Route path="/icon-logo-generator" element={<IconLogoGenerator />} />
      <Route path="/initial-logo-generator" element={<RandomStyleInitialLogos text='Java Script Object Notation'/>} />
      {/* <Route path="/custom-logo-design-service" element={<CustomDesign />} /> */}
      <Route path="/services/:slug" element={<CustomDesign />} />
      <Route path="/sample-logos" element={<Frame15 />} />
      {/* <Route path="/steps" element={<Home />} /> */}
      <Route path="/animation" element={<Animation />} />
      <Route path="/graphic-design-services" element={<Graphics />} />
      <Route path="/design" element={<BadgeStyleLogos />} />
    </Routes>
    </LogoProvider>
 
    );
  };
  
export default App;
