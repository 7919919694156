import React from "react";
import Navbar from "../components/Navbar";

import Graphics from "../components/Graphics";
import Footer from "../components/Footer";
import CustomLogoScroll from "../components/CustomLogoScroll";

import "../components/MakeLogo.css";

const GraphicDesignpage = () => {
    return (
        <>
            <div
                className="navbarOuterContainer" style={{ marginBottom: '5.4rem' }}>
                <Navbar />
            </div>
            <Graphics />
            <div className="px-3" style={{ background: "#F8F8F8", display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'auto' }}>
                <CustomLogoScroll />
            </div>

            <Footer />
        </>
    );
};

export default GraphicDesignpage;
