import React, { useState, useEffect, useContext } from 'react';
import styles from "./Navbar.module.css";
// import styles1 from "./Navbar1.module.css";
import { Link, useLocation, useHistory } from "react-router-dom";
import user from "../assets/User.png";
import call from "../assets/telephone-call 1.svg";
//import logo from "../assets/group-1000002329.svg";
import logo from "../assets/ADS Logo.svg";
import hamMenu from "../assets/menu-burger (1) 1.svg";
import './Navbar.css';
import { LogoContext } from '../contexts/LogoGeneratorContext';


const Navbar = () => {
    const [isNavCollapsed, setIsNavCollapsed] = useState(true);
    const [isSidebarVisible, setIsSidebarVisible] = useState(false);
    const location = useLocation();
    const { resetContextVariables } = useContext(LogoContext);

    useEffect(() => {
        if (location.pathname === '/') {
            resetContextVariables();
        }
    }, []);


    const handleNavCollapse = () => {
        setIsNavCollapsed(!isNavCollapsed);
        setIsSidebarVisible(!isSidebarVisible);
    };

    const handleCloseSidebar = () => {
        setIsNavCollapsed(true);
        setIsSidebarVisible(false);
    };
    return (
        <>
            <div className={`${styles.navbarParent}   d-none d-lg-flex`}>
                <div className={styles.navbarFirstComp}>
                    <Link to="/">
                        <div className={styles.logoMakerParent}>
                            <img
                                className={styles.groupItem}
                                alt="Logo Maker Logo"

                                src={logo}
                            />
                        </div>
                    </Link>



                    <ul className={styles.navbarSecondComp}>
                        <a href="https://next.alldesignsstudio.com/" style={{ textDecoration: 'none' }} rel="noopener noreferrer">
                            <li className={styles.menuItems}>Home</li>
                        </a>

                        {/* <Link to="/" style={{ textDecoration: 'none' }}><li className={styles.menuItems} >Home</li> </Link> */}
                        <Link to="/graphic-design-services" style={{ textDecoration: 'none' }}><li className={styles.menuItems}
                        >Graphic Design Services

                        </li></Link>
                        <li className={styles.menuItems}>Portfolio</li>
                        <li className={styles.menuItems}>About Us</li>
                        <li className={styles.menuItems}>FAQ</li>
                    </ul>





                    <div className={`d-flex justify-content-center align-items-center ${styles.navbarThirdComp}`} >
                        <p className={` mb-0 font-weight-normal${styles.menuNumber}`} style={{ marginRight: '0.57rem' }}>+9192442535</p>
                        <span className=" position-relative ">
                            <img
                                src={user}
                                alt="user"

                                style={{ borderRadius: "1.22px 0px 0px 0px" }}
                            />
                        </span>
                        <span className={` line-divider ${styles.line9}`}></span>
                        <div className="d-flex justify-content-center align-items-center  get-started-container">
                            <button className={`${styles.getStartedBtn}`}>Get Started</button>
                        </div>
                    </div>

                </div>

            </div>

            <div className="smallNavbar col-sm-12 col-xs-6">
                <Link to="/" style={{ textDecoration: 'none' }}>
                    <div className={styles.logoMakerParent} >
                        <img
                            className={styles.groupItem}
                            alt="Logo Maker Logo"

                            src={logo}
                        />
                    </div>
                </Link>

                <a className="close-btn" onClick={handleNavCollapse}>
                    <img src={hamMenu} alt="menu-burger" className='mb-2' />
                </a>
            </div>






            <div className={`col-xs-6 col-sm-12 sidebar ${isSidebarVisible ? "show" : ""}`}>
                <div className="header">
                    <Link to="/" style={{ textDecoration: 'none' }}>

                        <div className={styles.logoMakerParent} >
                            <img
                                className={styles.groupItem}
                                alt="Logo Maker Logo"

                                src={logo}
                            />
                        </div>
                    </Link>

                    <a className="close-btn mb-2" onClick={handleCloseSidebar} >
                        <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                    </a>
                </div>
                <div className="menu-items">
                    <Link to="/" style={{ textDecoration: 'none' }}> <div className="menu-item">Home</div></Link>
                    <Link to="/custom-logo-design-service" style={{ textDecoration: 'none' }}><div className="menu-item">Graphic Design Services</div></Link>
                    <div className="menu-item">Portfolio</div>
                    <div className="menu-item">About Us</div>
                    <div className="menu-item">FAQ</div>
                </div>
                <div className="footer">
                    <button className="get-started-btn">Get Started</button>
                    <div className="contact-info">
                        <div className=" d-flex justify-content-center align-items-center" style={{ gap: '0.88rem' }}>
                            <img src={call} className='m-0' style={{ width: '0.875rem', height: '0.875rem' }} />
                            <p className={` mb-0 font-weight-normal${styles.menuNumber}`} >+9192442535</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Navbar;